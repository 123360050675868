var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.scientificDegree.scientificDegreeImagePath,
            _vm.scientificDegree.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.scientificDegree.scientificDegreeImageIsDefault &&
          _vm.checkPrivilege(_vm.hasScientificDegreeDeleteImage())},on:{"changeValue":function($event){_vm.scientificDegree.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.scientificDegree.fullCode,"title":_vm.$t('ScientificDegrees.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.scientificDegree.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeNameAr"),"errors":_vm.errors_scientificDegreeNameAr,"value":_vm.scientificDegree.scientificDegreeNameAr,"title":_vm.$t('ScientificDegrees.nameAr'),"imgName":'scientificDegrees.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeNameAr = $event;
            _vm.$v.scientificDegree.scientificDegreeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeNameEn"),"errors":_vm.errors_scientificDegreeNameEn,"value":_vm.scientificDegree.scientificDegreeNameEn,"title":_vm.$t('ScientificDegrees.nameEn'),"imgName":'scientificDegrees.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeNameEn = $event;
            _vm.$v.scientificDegree.scientificDegreeNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeNameUnd"),"value":_vm.scientificDegree.scientificDegreeNameUnd,"title":_vm.$t('ScientificDegrees.nameUnd'),"imgName":'scientificDegrees.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeDescriptionAr"),"errors":_vm.errors_scientificDegreeDescriptionAr,"value":_vm.scientificDegree.scientificDegreeDescriptionAr,"title":_vm.$t('ScientificDegrees.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeDescriptionAr = $event;
            _vm.$v.scientificDegree.scientificDegreeDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeDescriptionEn"),"errors":_vm.errors_scientificDegreeDescriptionEn,"value":_vm.scientificDegree.scientificDegreeDescriptionEn,"title":_vm.$t('ScientificDegrees.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeDescriptionEn = $event;
            _vm.$v.scientificDegree.scientificDegreeDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeDescriptionUnd"),"value":_vm.scientificDegree.scientificDegreeDescriptionUnd,"title":_vm.$t('ScientificDegrees.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-scientificDegreeNotes"),"value":_vm.scientificDegree.scientificDegreeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.scientificDegree.scientificDegreeNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }